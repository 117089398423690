import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Util from "@components/core/util"
import PostCardList from "@templates/post-card-list"
import "@styles/templates/menu-page.scss"

const MenuConfig = Util.MenuConfig

const MenuPage = (props) => {
  const uri = props.uri
  const data = props.data
  const categoriesInMenu = Util.getCategoriesByMenuUri(data.blog.allCategories, uri, true)
  const postsInMenu = Util.getPostsInMenu(data, uri)

  const [currentPage, setCurrentPage] = useState(1)
  const [categories, setCategories] = useState(categoriesInMenu)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [posts, setPosts] = useState(postsInMenu)
  const [isShownCategories, showCategories] = useState(false)

  let menu = MenuConfig[uri]
  if (!menu) menu = { className: '', title: '-', subTitle: '-'}

  if (typeof props.currentPage === 'undefined') setCurrentPage(1)
  console.log('Current Page: ' + currentPage)

  useEffect(() => {
    setCurrentPage(props.currentPage)
  }, [props.currentPage])

  // Click Category
  const filterPostsByCategory = (category) => {
    let targetId = category.id
    let filteredPosts = [], refreshedCategories = []

    if (targetId === null) filteredPosts = postsInMenu
    else filteredPosts = postsInMenu.filter((post) => ( post.category.id === targetId ))

    refreshedCategories = categoriesInMenu.map((item) => {
      let isActive = (item.id === targetId)
      return { id: item.id, name: item.name, isActive: isActive }
    })

    setPosts(filteredPosts)
    setCurrentPage(1)
    setCategories(refreshedCategories)
    setSelectedCategory(category)
    showCategories(false)
  }

  return (
    <div className="menu-page-container">
      <h1 className="menu-page-header common-page-header">
        <span className="title">{menu.title}</span>
        <span className="sub-title">
          { (selectedCategory && selectedCategory.id) ? selectedCategory.name : '' }
        </span>
      </h1>

      <div className={"block category " + (isShownCategories ? 'show' : '')}>
        <div className="category-header">
          <span className="btn-toggle-category cursor-pointer"
            onClick={() => {showCategories(true)}}>
            카테고리
            <span className="ic ic-arrow cursor-pointer"></span>
          </span>
        </div>
        <div className="category-list">
          <span className="ic btn-close cursor-pointer"
            onClick={() => { showCategories(false) }}></span>
          {
            categories.map((category, index) => {
              return (
                <Link className={"btn-category cursor-pointer " + (category.isActive ? 'active' : '' )}
                  key={index}
                  to={uri}
                  state={{
                    currentPage: 1
                  }}
                  onClick={() => {
                    filterPostsByCategory(category)
                  }}>
                    {category.name}
                </Link>
              )
            })
          }
        </div>
      </div>

      <div className="block posts">
        <PostCardList
          currentPage={currentPage}
          uri={uri}
          postList={posts}/>
      </div>
    </div>
  )
}

export default MenuPage
