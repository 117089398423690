import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "@components/layout"
import MenuPage from "@templates/menu-page"
import SEO from "@components/seo"
import Util from "@components/core/util.js"

const seo = {
  title: '뉴스',
  url: 'https://blog.buildit.kr/news/',
  isHome: false,
  isPost: false,
  linkKey: 'news',
  isMenu: true,
  uri: '/news'
}

const News = (props) => {
  let currentPage = Util.getCurrentPageFromLocationProp(props)
  const data = useStaticQuery(graphql`
    query {
      blog {
        allCategories {
          id
          name
          menu {
            id
            urlLink
            name
          }
        }
        allPosts(sort: DATE_DESC) {
          edges {
            node {
              id
              title
              thumbnailPath
              menuId
              menu {
                id
                name
                urlLink
                parent {
                  id
                  name
                  urlLink
                }
              }
              categoryId
              category {
                id
                menuId
                name
              }
              contentNl
              isRecommended
              date
              desc
            }
          }
        }
      }
    }
  `)

  const postsInMenu = Util.getPostsInMenu(data, seo.uri)
  let schemaOrgJSONLD = Util.getJsonLDFormatted(postsInMenu.slice(0, 4))

  return (
    <Layout>
      <SEO 
        title={seo.title}
        url={seo.url}
        isHome={seo.isHome}
        isPost={seo.isPost}
        linkKey={seo.linkKey}
        isMenu={seo.isMenu}
        schemaOrgJSONLD={schemaOrgJSONLD} />
      <div className="manu-page news">
        <MenuPage
          currentPage={currentPage} 
          uri={props.uri}
          data={data}/>
      </div>
    </Layout>
  )
}

export default News
